import React, { ReactFragment, useState } from "react";
import Layout from "../../../components/generator/Layout";
import { Main } from "../../../components/Main";

const Dash = () => <div>Dash</div>;

export default function AppShellDemo() {
  if(typeof window === `undefined`) return null;
  
  return (
    <Layout>
      <Main />
    </Layout>
  );
}
