import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../../components/site/Wrapper";
import { Image } from "../../components/site/Image";
import { Button } from "../../components/site/Button";
import AppShellDemo from './generator/index'
import { Router, useNavigate } from "@reach/router";
import netlifyIdentity from "netlify-identity-widget";

const AuthButton = ({ history }) => {

    const navigate = useNavigate();

    return netlifyAuth.isAuthenticated ? (
    <p>
      Welcome!{" "}
      <button
        onClick={() => {
          netlifyAuth.signout(() => navigate('/'));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <button
      onClick={() => {
        netlifyAuth.authenticate(() =>navigate('/generator'));
      }}
    >
      Sign In
    </button>
  );
};

const netlifyAuth = {
  isAuthenticated: false,
  user: null,
  authenticate(callback) {
    this.isAuthenticated = true;
    netlifyIdentity.open();
    netlifyIdentity.on("login", (user) => {
      this.user = user;
      callback(user);
    });
  },
  signout(callback) {
    this.isAuthenticated = false;
    netlifyIdentity.logout();
    netlifyIdentity.on("logout", () => {
      this.user = null;
      callback();
    });
  },
};

function PrivateRoute({ component: Component, ...rest }) {
  if (netlifyAuth.isAuthenticated) {
    return <Component {...rest} />;
  }
  return (
    <div>
      <AuthButton />
      {/*  */}
    </div>
  );
}

const App = (props) => {
  console.log(props);
  const books = props?.data?.allBooksJson?.edges;
  //   const Default = ()=>children;
  const Login = () => (
    <div>
      <h1>Login Required</h1>

      <AuthButton />
    </div>
  );
  const Profile = () => <div>Profile</div>;
  return (
    <Wrapper>
      <Router basepath="/app">
        <PrivateRoute path="profile" component={Profile} />
        <PrivateRoute path="generator" component={AppShellDemo} />
        <Login default />
      </Router>
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          pages
        }
      }
    }
  }
`;

export default App;
