import React from "react";

export const Button = (props) => {
    const {
        as: Component = "button", children, additionalClasses, className, variant = "primary", ...remaining
    } = props;

    return (
        <Component {...props} type="button" className={className || `btn text-white fw-bold btn-${variant} ${additionalClasses}`}>
            {children}
        </Component>
    );
};
